import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { darken } from 'polished'

export const LogoContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 40px 0px 20px;
  #target {
    width: 90px;
  }
  #costco {
    width: 200px;
  }
  #bestbuy {
    width: 140px;
  }
  #sams {
    width: 240px;
  }
  #walmart {
    width: 240px;
  }
  #amazon {
    width: 200px;
  }
  @media (max-width: ${breakpoints.l}px) {
    gap: 0px;
    column-gap: 5px;
    #target {
      width: 70px;
    }
    #bestbuy {
      width: 120px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    #target {
      width: 60px;
    }
    #bestbuy {
      width: 100px;
    }
  }
`
export const Card = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  min-height: 150px;
  @media (max-width: ${breakpoints.s}px) {
    height: 100%;
    min-height: 90px;
  }
  :hover {
    background-color: #efefef;
  }
  img {
    max-width: 100%;
  }
`
export const Selection = styled.div`
  width: 120px;
  display: inline-block;
  margin: 10px;
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.coral : props.theme.cream)};
  border: ${(props) =>
    props.selected ? `1px solid ${props.theme.coral}` : `1px solid ${props.theme.coral}`};
  color: ${(props) => (props.selected ? props.theme.cream : props.theme.coral)};
  border-radius: 28px;
  text-align: center;
  :hover {
    background-color: ${(props) => darken(0.1, props.theme.coral)};
    color: ${(props) => props.theme.cream};
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 100px;
    display: inline;
    padding: 8px 16px;
    margin: 7px;
  }
`
