import React from 'react'
import styled from 'styled-components'
import { BigHeader, breakpoints } from 'src/utils/styles/global-styles'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import SEO from '../../components/seo'
import RetailPartners from '../../components/RetailPartners'

const Retailers = () => {
  const { comboProduct } = useStaticQuery(graphql`
    query RetailPartnersQuery {
      comboProduct: file(relativePath: { eq: "product-combo.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Retail Partners | Skylight"
        description="Find a Skylight Frame or Skylight Calendar at a retailer near you! Or buy a Skylight Frame or Skylight Calendar directly from skylightframe.com."
        imagePath={comboProduct.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      <ImageContainer>
        <GatsbyImage
          image={comboProduct.childImageSharp.gatsbyImageData}
          alt="Skylight Frame and Skylight Calendar on a beautiful table"
        />
      </ImageContainer>
      <Container>
        <Header>Find a Skylight retailer near you</Header>
        <Text>Select a product below to see a list of in-store and online Skylight Retailers</Text>
        <RetailPartners />
      </Container>
    </>
  )
}

export default Retailers

export const Container = styled.div`
  width: 90%;
  margin: auto;
  padding-bottom: 100px;
  text-align: center;
`
export const Header = styled(BigHeader)`
  margin: 3rem 0px 1rem 0px;
  text-align: center;
  @media (max-width: ${breakpoints.s}px) {
    margin: 2rem 0px 0.5rem 0px;
    font-size: 24px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
export const Text = styled.p`
  margin-bottom: 30px;
`
export const ImageContainer = styled.div`
  width: 1000px;
  margin: auto;
  max-width: 100%;
`
